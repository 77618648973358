<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <!-- <el-button class="mr10" type="primary">添加分类</el-button> -->
        <!-- <el-select
          v-model="classifytype"
          placeholder="请选择文章类型查询"
          class="mr-16"
        >
          <el-option
            v-for="i in classfiyTypeList"
            :key="i.id"
            :label="i.name"
            :value="i.id"
          >
          </el-option>
        </el-select> -->
        <el-input class="w220 mr10" v-model="keyWord" placeholder="请输入标题查询" />
        <el-date-picker v-model="pickerValue" class="mr10" align="right" value-format="yyyy-MM-dd" type="daterange"
          start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" />
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button type="primary" @click="$router.push('/isDrafts')"
          >草稿箱</el-button
        > -->
        <el-button class="ml10" type="primary" @click="centerDialogVisible = true">发布{{ title_info }}</el-button>
      </div>
    </div>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="title" align="center" show-overflow-tooltip :label="title_info+'标题'" width="150" />
        <el-table-column prop="originAuthor" align="center" label="来源">
          <template slot-scope="scope">
            {{ scope.row.originAuthor ? scope.row.originAuthor : "——" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" width="200px">
          <template slot-scope="scope">
            {{ setDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="shareNum" align="center" label="分享次数" />
        <el-table-column prop="readNum" align="center" label="阅读次数" />
        <el-table-column prop="readTime" align="center" label="阅读时长" />
        <el-table-column align="center" :label="title_info+'分类'">
          <div slot-scope="scope">
            <div v-if="scope.row.labelId">
              <div v-for="(i, n) in formDataLabel(scope.row.labelId)" :key="n">
                {{ i }}
              </div>
            </div>

            <div v-else>-</div>
          </div>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="创建者" />
        <el-table-column align="center" label="发布状态">
          <template slot-scope="scope">
            <el-switch :value="scope.row.isDrafts == 1 ? false : true"
              @change="(e) => switchChange(e, scope.row.dynamicId)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="直接打开">
          <template slot-scope="scope" v-if="scope.row.isExplore==1">
            <el-switch :value="scope.row.isOpen == 1 ? true : false"
              @change="(e) => switchChangeIsOpen(e, scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isTop" align="center" label="是否置顶">
          <template slot-scope="scope">
            <el-switch :value="scope.row.isTop == 1 ? true : false"
              @change="(e) => switchChange(e, scope.row.dynamicId, 'stick')">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="edit(scope.row)">编辑</el-button>
            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="del(scope.row.dynamicId)"
              icon="el-icon-info" icon-color="red" title="确定要删除这篇文章吗？">
              <el-button slot="reference" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :title="'发布'+title_info" :visible.sync="centerDialogVisible" :modal-append-to-body="false"
      :close-on-click-modal="false" :destroy-on-close="true" width="50%" center>
      <postArticles :title_info="title_info" @submitForm="submitForm" ref="postArticles" :classify="classify" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import postArticles from "./components/postArticles";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  getDynamicInfo,
  updateDynamicState,
  addDynamicInfo,
  delDynamicInfo,
  updateDynamicTop,
  labelQueryPage,
} from "@/api/articleManagement";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
    postArticles,
    customPopconfirm,
  },
  props: ['classify','title_info'],
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "", //文章标题
      pickerValue: "", //选择时间
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableData: [],
      labelData: [],
      classifytype: '',
      classfiyTypeList: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 1,
          name: '获客文章'
        },
        {
          id: 2,
          name: '产品介绍'
        },
        {
          id: 3,
          name: '解决方案'
        }
      ]
    };
  },
  created() {
    this.getDynamicInfoAsync();
    this.getLable();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    // 根据id获取列表标签
    getLable() {
      let data = {
        pageNum: 1,
        classify: this.classify,
        pageSize: 100,
      };
      labelQueryPage(data).then((res) => {
        this.labelData = res.data.records;
      });
    },
    formDataLabel(str) {
      let arr = [];
      if (str && str !== "") {
        let arr1 = str.split(",");
        this.labelData.forEach((el) => {
          arr1.forEach((els) => {
            if (el.labelId == els) {
              arr.push(el.labelName);
            }
          });
        });
      }
      if (arr.length < 1) {
        arr = ''
      }
      return arr;
    },
    /**@method 获取文章管理 */
    async getDynamicInfoAsync(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        classify: this.classify,
        isDrafts: 0,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await getDynamicInfo(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDynamicInfoAsync();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDynamicInfoAsync();
    },
    switchChangeIsOpen(e,item){
      let data=JSON.parse(JSON.stringify(item))
      data.isOpen=e?'1':'0';
      this.submitForm(data)
    },
    /**@method 切换文章发布状态
     * @param {Boolean} e - switch值
     * @param {String} id - id
     */
    async switchChange(e, id, type) {
      let data = {
        dynamicId: id,
        isDrafts: e ? 0 : 1,
      };
      if (type) {
        data = {
          dynamicId: id,
          isTop: e ? 1 : 0,
        };
        updateDynamicTop(data)
          .then((res) => {
            if (e) {
              this.$message.success("置顶成功！");
            } else {
              this.$message.success("取消置顶！");
            }
            this.getDynamicInfoAsync();
          })
          .catch((err) => {
            if (err.code == 201) {
              this.$message.error(err.message);
            }
          });
      } else {
        await updateDynamicState(data);
        await this.getDynamicInfoAsync();
      }
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        startTime: this.pickerValue ? `${this.pickerValue[0]} 00:00:00` : "",
        endTime: this.pickerValue ? `${this.pickerValue[1]} 23:59:59` : "",
        title: this.keyWord,
        classify: this.classify
      };
      this.getDynamicInfoAsync(data);
    },
    determine(data) {
      this.$refs.postArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        isDrafts: val.isDrafts ? 0 : 1,
        type: 1,
        ...val,
      };
      try {
        await addDynamicInfo(data, { showLoading: true });
        this.centerDialogVisible = false;
        await this.getDynamicInfoAsync();
      } catch {
        // console.log(error,'error-------');
        // this.$message.error(error.message);
        this.$refs.postArticles.baocuo();
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.centerDialogVisible = true;
      const {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        createUserId,
        createTime,
        isDel,
        labelId,
        isTop,
        isDrafts,
        isExplore,
        exploreId,
        lengthPhotos,
        tookType,
        selectClass,
        classify,
        buttonName,
        isOpen,
        allowCopy,
        subTitle,
        shardImage,
        linkShortVideo,
        shortVideoId
      } = val;
      let data = {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        createUserId,
        createTime,
        isDel,
        labelId: labelId,
        isTop: isTop ? true : false,
        isDrafts: isDrafts ? true : false,
        isExplore: isExplore == 0 ? false : true,
        exploreId,
        lengthPhotos,
        tookType,
        selectClass,
        classify,
        buttonName,
        isOpen,
        allowCopy,
        subTitle,
        shardImage,
        linkShortVideo,
        shortVideoId
      };
      if (data.labelId) {
        data.labelId = data.labelId.split(",");
      }
      console.log(data, "data");
      this.$nextTick(() => {
        this.$refs.postArticles.ruleForm = data;
        this.$refs.postArticles.isExplore = data.isExplore == 1 ? true : false;
        this.$refs.postArticles.linkShortVideo = data.linkShortVideo == 1 ? true : false;
        if(data.isExplore==1){
          this.$refs.postArticles.isOpen = data.isOpen == 1 ? true : false;
          this.$refs.postArticles.allowCopy = data.allowCopy == 1 ? true : false;
        }
        this.$refs.postArticles.fileList.push({ name: "", url: photos });
        this.$refs.postArticles.fileListShare.push({ name: "", url: shardImage });
        if (data.classify == 3) {
          this.$refs.postArticles.fileListLength.push({
            name: "",
            url: lengthPhotos,
          });
        }
        if(data.linkShortVideo==1){
          this.$refs.postArticles.getVideo();
        }
        this.$refs.postArticles.getTagsList(data.classify);
        this.$refs.postArticles.getClassfiyList();
        if (data.exploreId) {
          this.$refs.postArticles.getapplyList();
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delDynamicInfo({ dynamicId: val });
        await this.getDynamicInfoAsync();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>