<template>
  <div class="">
    <el-card shadow="never">
      <!-- <div class="flex ju-be">
        <div class="flex">
          <el-select v-model="classifyType" placeholder="请选择文章类型查询" class="mr-16">
            <el-option v-for="i in classfiyTypeList" :key="i.id" :label="i.name" :value="i.id">
            </el-option>
          </el-select>
          <el-button type="primary" @click="getData(), currentPage = 1">查询</el-button>
        </div>
      </div> -->
      <el-button type="primary" @click="(dialogVisible = true), (ruleForm = {}), (isEdit = false)">添加分类</el-button>
      <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
        <template v-slot:table>
          <el-table-column type="index" align="center" label="序号" />
          <el-table-column prop="labelName" align="center" label="标题" />
          <!-- <el-table-column align="center" label="文章类型">
            <div slot-scope="scope">
              {{
                scope.row.classify == 1
                ? "获客文章"
                : scope.row.classify == 2
                  ? "产品介绍"
                  : "解决方案"
              }}
            </div>
          </el-table-column> -->
          <el-table-column prop="" align="center" label="排序">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="数值越大，分类排序越靠前" placement="top-start">
                <el-input v-model="scope.row.sortNum" placeholder="数值越大，分类排序越靠前" @change="saveSort(scope.row)" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="openLog(scope.row, 1)">设置</el-button>
              <el-popconfirm class="ml-12" title="是否删除标签？" @confirm="openLog(scope.row, 2)">
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog title="添加分类" :visible.sync="dialogVisible" width="50%" :modal-append-to-body="false"
      :destroy-on-close="true" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px">
        <!-- <el-form-item label="分类类型">
          <el-select v-model="ruleForm.classify" placeholder="请选择" style="width: 100%">
            <el-option v-show="item.id !== 99" v-for="item in classfiyTypeList" :key="item.id" :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="分类名称" prop="title">
          <el-input v-model="ruleForm.labelName" placeholder="请输入分类名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<script>
import commonTable from "@/components/common/commonTable";
//标签分类
import {
  labelAdd,
  labelQueryPage,
  labelUpdate,
  labelDeleteById,
} from "@/api/articleManagement";
export default {
  components: {
    commonTable,
  },
  props:['classify'],
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {},
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      isEdit: false,
      classifyType: 99,
      classfiyTypeList: [
        {
          id: 99,
          name: "全部",
        },
        {
          id: 1,
          name: "获客文章",
        },
        {
          id: 2,
          name: "产品介绍",
        },
        {
          id: 3,
          name: "解决方案",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async saveSort(row) {
      if (row && row.sort != "") {
        console.log(row);
        const result = await labelUpdate(row);
        console.log("result:", result);
        this.$message({
          type: "info",
          message: "保存成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    submit() {
      if (!this.ruleForm.sortNum || this.ruleForm.sortNum == "") {
        this.ruleForm.sortNum = 1;
      }
      // if (!this.ruleForm.classify||this.ruleForm.classify==99) {
      //   return this.$message.warning("请选择分类类型");
      // }
      if (!this.ruleForm.labelName) {
        return this.$message.warning("请输入分类名称");
      }
      if (this.ruleForm.labelName) {
        if (!this.isEdit) {
          this.ruleForm.classify=this.classify;
          labelAdd(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success("保存成功！");
              this.dialogVisible = false;
              this.getData();
            }
          });
        } else {
          labelUpdate(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success("保存成功！");
              this.dialogVisible = false;
              this.getData();
            }
          });
        }
      }
    },
    getData() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        classify: this.classify,
      };
      labelQueryPage(data).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    openLog(item, i) {
      if (i == 1) {
        this.ruleForm = item;
        this.isEdit = true;
        this.dialogVisible = true;
      } else if (i == 2) {
        labelDeleteById({ labelId: item.labelId }).then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.getData();
          }
        });
      }
    },
  },
};
</script>
    
<style></style>